<template>
  <section class="app-ecommerce-details">
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li v-for="(value, valIndex) in values" :key="valIndex">
            {{ value }}
          </li>
        </ul>
      </div>
    </b-alert>
    <!-- Content -->
    <b-card v-if="varData" no-body>
      <b-card-body>
        <b-row class="my-2">
          <!-- Left: varData Image Container -->
          <b-col
            cols="12"
            md="5"
            class="d-flex align-items-center justify-content-center mb-2 mb-md-0"
          >
            <div class="d-flex align-items-center justify-content-center">
              <b-img
                :src="varImage"
                :alt="`Image of ${varName}`"
                class="varData-img"
                fluid
              />
            </div>
          </b-col>
          <!-- Right: varData Details -->
          <b-col cols="12" md="7">
            <!-- varData Name -->
            <h4>{{ varName }}</h4>
            <!-- varData Brand -->
            <!-- <b-card-text class="item-company mb-0">
                            <span>by</span>
                            <b-link class="company-name"> -->
            <!-- {{ varData.product.storename }} -->
            <!-- </b-link>
                        </b-card-text> -->
            <!-- Price And Ratings -->
            <div class="ecommerce-details-price d-flex flex-wrap mt-1">
              <h4 class="item-price mr-1">{{ varData.price }} EGP</h4>
              <ul class="unstyled-list list-inline pl-1 border-left">
                <li
                  v-for="star in 5"
                  :key="star"
                  class="ratings-list-item mr-25"
                >
                  <feather-icon
                    icon="StarIcon"
                    size="18"
                    :class="[
                      { 'fill-current': star <= varData.average_rate },
                      star <= varData.average_rate
                        ? 'text-warning'
                        : 'text-muted',
                    ]"
                  />
                </li>
              </ul>
            </div>
            <!-- Avability -->
            <b-card-text v-if="varData.stock_status == 'in-stock'"
              >Available -
              <span class="text-success">In stock</span></b-card-text
            >
            <b-card-text v-else
              >Available -
              <span class="text-danger">out of stock</span></b-card-text
            >
            <!-- varData Short Description -->
            <b-card-text>{{ varShortDesc }}</b-card-text>
            <!-- varData Description -->
            <b-card-text v-html="varLongDesc"></b-card-text>
            <hr />
            <div v-if="delCat == true" class="d-flex flex-column flex-sm-row">
              <b-button
                block
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="warning"
                class="mr-1 mt-1"
                :to="{ path: '/ShowProduct/variant/show/' + varData.id }"
              >
                <feather-icon icon="EyeIcon" class="mr-50" />
                <span>show</span>
              </b-button>
              <b-button
                block
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="warning"
                class="mr-1 mt-1"
                :to="{ path: '/ShowProduct/update/variant/' + varData.id }"
              >
                <feather-icon icon="RefreshCwIcon" class="mr-50" />
                <span>Update</span>
              </b-button>
              <b-button
                block
                v-if="per.includes('update') && varData.status === 'disabled'"
                @click="activeVariant(varData.id)"
                variant="outline-info"
                class="mr-1 mt-1"
              >
                Active
              </b-button>

              <b-button
                block
                v-b-modal.modal-deactive
                v-if="per.includes('update') && varData.status === 'enabled'"
                variant="outline-info"
                class="mr-1 mt-1"
              >
                Deactive
              </b-button>
              <b-modal
                id="modal-deactive"
                cancel-variant="outline-secondary"
                @ok="deActiveVariant(varData.id)"
                ok-title="Ok"
                cancel-title="Close"
                centered
                title="Deactive Varaint"
              >
                <b-form>
                  <b-form-checkbox
                    id="checkbox-1"
                    v-model="deactive"
                    name="checkbox-1"
                    value="1"
                    unchecked-value="0"
                  >
                    Force Deactive
                  </b-form-checkbox>
                </b-form>
              </b-modal>
              <b-button
                block
                @click="deleteVariant(varData.id)"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="danger"
                class="mr-1 mt-1"
              >
                <feather-icon icon="DeleteIcon" class="mr-50" />
                <span>Delete</span>
              </b-button>
            </div>
            <hr />
            <!-- attributes -->
            <div
              v-if="attrData.length > 0"
              v-for="(item, index) in attrData"
              :key="index"
            >
              <h5 class="mt-2">{{ item.translation.name }}:</h5>
              <div class="d-flex flex-row">
                <div v-for="(vale, ind) in item.values" :key="ind">
                  <b-button
                    class="m-1"
                    v-if="vale.active"
                    @click="showVar(vale.translation.name)"
                    style="
                      border-width: 4px !important;
                      font-weight: bold;
                      text-decoration: underline;
                    "
                    variant="outline-primary"
                    >{{ vale.translation.name }}</b-button
                  >
                  <b-button
                    class="m-1"
                    v-else
                    @click="showVar(vale.translation.name)"
                    variant="outline-primary"
                    >{{ vale.translation.name }}</b-button
                  >
                </div>
              </div>
            </div>
            <hr />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </section>
</template>

<script>
export default {
  data() {
    return {
      deactive: "0",
      showDismissibleAlert: false,
      errors_back: [],
      varName: "",
      varImage: "",
      varShortDesc: "",
      varLongDesc: "",
      varData: {},
      deletedVariant: false,
      delCat: true,
      attrData: [],
      sku: 0,
      per: [],
    };
  },
  computed: {
    slug: function () {
      return this.$route.query.slug;
    },
  },
  watch: {
    // call method if the route changes
    $route: "reInitialize",
  },
  created() {
    this.sku = this.$route.params.id;

    this.getData(this.slug);
  },
  mounted() {},
  methods: {
    reInitialize: function () {
      this.getData(this.slug);
    },
    getData(slug) {
      axios
        .get(slug)
        .then((result) => {
          this.attrData = [];
          this.varData = {};
          this.varData = result.data.data.variant;
          if (this.varData.deleted_at != null) {
            this.delCat = false;
          } else {
            this.delCat = true;
          }
          if (this.varData.Media.length > 0) {
            this.varImage = this.varData.Media[0].name;
          } else {
            this.varImage = this.varData.product.Media[0].name;
          }
          this.varData.average_rate = this.varData.product.average_rate;
          this.$route.query.slug = this.varData.translation.slug;
          this.varName = this.varData.translation.name;
          this.varShortDesc = this.varData.translation.short_description;
          this.varLongDesc = this.varData.translation.description;

          for (let ind in result.data.data.attribute) {
            this.attrData.push(result.data.data.attribute[ind]);
          }
        })
        .catch((err) => {
          this.errors_back.length = 0;
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              });
            } else {
              this.errors_back = err.response.data.data;
            }
            this.showDismissibleAlert = true;
          } else {
            this.errors_back = [];
            this.errors_back.push({
              error: "internal server error",
            });
            this.showDismissibleAlert = true;
          }
        });
    },
    showVar(value) {
      axios
        .get(this.slug + "/" + value)
        .then((result) => {
          this.varData = result.data.data.variant;
          //  if (this.varData.Media.length >0 ){
          //     this.varImage = this.varData.Media[0].name
          // }
          // else {
          //     this.varImage = this.varData.product.Media[0].name
          // }
          // this.varName = this.varData.translation.name;
          // this.varShortDesc = this.varData.translation.short_description
          // this.varLongDesc = this.varData.translation.description
          // this.attrData.length = 0
          // for (let ind in result.data.data.attribute) {
          //     this.attrData.push(result.data.data.attribute[ind])
          // }
          // this.getData(this.varData.translation.slug)
          this.$router.push({
            path: "/ShowProduct/Variant/" + this.varData.sku,
            query: { slug: this.varData.translation.slug },
          });
        })
        .catch((err) => {
          this.errors_back.length = 0;
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              });
            } else {
              this.errors_back = err.response.data.data;
            }
            this.showDismissibleAlert = true;
          } else {
            this.errors_back = [];
            this.errors_back.push({
              error: "internal server error",
            });
            this.showDismissibleAlert = true;
          }
        });
    },
    deleteVariant(id) {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to delete varaint.", {
          title: "Are You Sure?",
          size: "sm",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .delete("actions/variants/" + id + "/archive")
              .then((result) => {
                this.$swal({
                  position: "center",
                  icon: "success",
                  title: "Your work has been saved",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.getData(this.slug);
              })
              .catch((err) => {
                this.$swal({
                  position: "center",
                  icon: "error",
                  title: "Error!",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.errors_back.length = 0;
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    });
                  } else {
                    this.errors_back = err.response.data.data;
                  }
                  this.showDismissibleAlert = true;
                } else {
                  this.errors_back = [];
                  this.errors_back.push({
                    error: "internal server error",
                  });
                  this.showDismissibleAlert = true;
                }
              });
          }
        });
    },
    activeVariant(id) {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to active varaint.", {
          title: "Are You Sure?",
          size: "sm",
          okVariant: "info",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .get("variants/" + id + "/active-variant")
              .then((result) => {
                this.$swal({
                  position: "center",
                  icon: "success",
                  title: "Your work has been saved",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.getData(this.slug);
              })
              .catch((err) => {
                this.$swal({
                  position: "center",
                  icon: "error",
                  title: "Error!",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.errors_back.length = 0;
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    });
                  } else {
                    this.errors_back = err.response.data.data;
                  }
                  this.showDismissibleAlert = true;
                } else {
                  this.errors_back = [];
                  this.errors_back.push({
                    error: "internal server error",
                  });
                  this.showDismissibleAlert = true;
                }
              });
          }
        });
    },
    deActiveVariant(id) {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to deactive varaint.", {
          title: "Are You Sure?",
          size: "sm",
          okVariant: "info",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .get("actions/variants/" + id + "/deactive/" + this.deactive)
              .then((result) => {
                this.$swal({
                  position: "center",
                  icon: "success",
                  title: "Your work has been saved",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.getData(this.slug);
              })
              .catch((err) => {
                this.$swal({
                  position: "center",
                  icon: "error",
                  title: "Error!",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.errors_back.length = 0;
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    });
                  } else {
                    this.errors_back = err.response.data.data;
                  }
                  this.showDismissibleAlert = true;
                } else {
                  this.errors_back = [];
                  this.errors_back.push({
                    error: "internal server error",
                  });
                  this.showDismissibleAlert = true;
                }
              });
          }
        });
    },
    restoreItem(id) {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to restore variant.", {
          title: "Are You Sure?",
          size: "sm",
          okVariant: "warning",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .post("variants/" + id + "/restore-variant")
              .then((result) => {
                this.$swal({
                  position: "center",
                  icon: "success",
                  title: "Your work has been saved",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.getData(this.slug);
              })
              .catch((err) => {
                this.$swal({
                  position: "center",
                  icon: "error",
                  title: "Error!",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.errors_back.length = 0;
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    });
                  } else {
                    this.errors_back = err.response.data.data;
                  }
                  this.showDismissibleAlert = true;
                } else {
                  this.errors_back = [];
                  this.errors_back.push({
                    error: "internal server error",
                  });
                  this.showDismissibleAlert = true;
                }
              });
          }
        });
    },
    isString(value) {
      return typeof value === "string" || value instanceof String;
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce-details.scss";
</style>
